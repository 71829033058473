<template>
  <div style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class='d-flex flex-row align-center'>
          <v-btn text class="mx-2" @click="()=>{this.$router.go(-1)}">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
          <v-progress-circular
              indeterminate
              color="green"
              v-if="loader"
              style="margin-left: 10px;"
          ></v-progress-circular>
          <div style="width: 600px;" class="d-flex flex-row align-center">
            <div v-if="!editMode" class="d-flex flex-column">
                <h1 >{{this.singular}}: {{this.data.name}}</h1>
                <span style="font-size: 12px;">Created At: {{ utils.formatDate(data.createdAt, 'withTime') }}</span>
                <span style="font-size: 12px;">Created By: {{ lookupUsername(data.createdBy) }}</span>
              </div>
            <v-text-field v-if="editMode" outlined label="Promotion Name" v-model="data.name"/>
            <v-btn v-if="!editMode" @click="editMode = true" fab x-small class="ml-2" color="info"><v-icon>mdi-pencil</v-icon></v-btn>
            <v-btn v-if="editMode" @click="editMode = false" fab x-small class="ml-2" color="warning"><v-icon>mdi-close</v-icon></v-btn>
            <v-btn :loading="updateIsLoading" @click="update" fab x-small class="ml-2" color="success"><v-icon>mdi-content-save</v-icon></v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="data.metadata"> 
        <v-col>
          <v-card outlined>
            <v-card-title>Promotion Duration</v-card-title>
            <v-card-text class="d-flex flex-row">
              <div class="d-flex flex-row ml-3">
                <span class="d-flex flex-row">
                  <v-menu
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="data.metadata.startDate"
                        label="Start Date"
                        readonly
                        clearable
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        class="mr-2"
                        dense
                      />
                    </template>
                    <v-date-picker
                        v-model="data.metadata.startDate"
                    />
                  </v-menu>
                  <v-menu
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="data.metadata.endDate"
                          label="End Date"
                          readonly
                          clearable
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          dense
                      />
                    </template>
                    <v-date-picker v-model="data.metadata.endDate"/>
                  </v-menu>
                </span>
                <v-checkbox v-model="data.metadata.promotionOngoing" label="Promotion is ongoing."/>
                <v-checkbox v-model="data.metadata.stackable" label="Promotion can stack with other promotions."/>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-card-title>Available To</v-card-title>
            <v-card-text>
              <v-select
                dense
                :items="availableToTypes"
                label="Available To"
                outlined
                v-model="data.metadata.availableToType"
              ></v-select>
              <v-text-field v-if="data.metadata.availableToType == 'The First X Customers'" dense outlined label="How many customers?" v-model="data.metadata.firstXCustomers"/>
              <v-text-field v-if="data.metadata.availableToType == 'The Xth Customer'" dense outlined label="The Value of X" v-model="data.metadata.theXCustomer"/>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="data.metadata">
        <v-col cols="4">
          <v-card>
            <v-card-title class="d-flex flex-row">
              <span class="mr-2">Conditions</span>
              <v-icon :color="this.lastScanTarget == 'conditions'?'success':''" @click="lastScanTarget = 'conditions'">mdi-barcode-scan</v-icon>
            </v-card-title>
            <v-card-text>
              <div class="d-flex flex-column">
                <v-select
                  dense
                  :items="conditions"
                  label="Conditions"
                  v-model="data.metadata.condition"
                  @change="lastScanTarget = 'conditions'"
                  outlined
                ></v-select>
                <div v-if="data.metadata.condition=='Minimum Order Amount'">
                  <v-text-field dense outlined type=number label="Amount" v-model="data.metadata.minOrderAmountConditionValue"/>
                </div>
              </div>
              <div class="d-flex flex-column" v-if="data.metadata.condition == 'Purchase Product Combination'">
                <v-btn @click="addNewPPCItem" class="mb-4" small style="width: 200px;" color="info">Add Product</v-btn>
                <div v-if="data.metadata.ppcItems">
                  <div v-for="(item, index) in data.metadata.ppcItems" :key="index">
                    <span class="d-flex flex-row">
                      <v-text-field :hint="item.productName + ' ' + utils.formatCurrency(item.regularPrice)" persistent-hint dense outlined label="Product ID" v-model="item.productId"/>
                      <v-text-field class="ml-2" dense type=number outlined label="Quantity" v-model="item.quantity"/>
                      <v-btn @click="removePPCItem(index)" fab x-small class="ml-2" color="error"><v-icon>mdi-close</v-icon></v-btn>
                    </span>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="8">
          <v-card>
            <v-card-title class="d-flex flex-row">
              <span class="mr-2">Rewards</span>
              <v-icon :color="this.lastScanTarget == 'rewards'?'success':''" @click="lastScanTarget = 'rewards'">mdi-barcode-scan</v-icon>
            </v-card-title>
            <v-card-text>
              <div>
                <v-select
                  dense
                  :items="rewards"
                  label="Rewards"
                  outlined
                  @change="lastScanTarget = 'rewards'"
                  v-model="data.metadata.reward"
                ></v-select>
                <span v-if="data.metadata.reward=='Discount on Products'" class="d-flex flex-column">
                  <span class="d-flex flex-row">
                    <v-btn @click="addNewDiscountOnProductsItem" class="mb-4 mr-2" small style="width: 200px;" color="info">Add Product</v-btn>
                    <v-btn @click="openImportProductsFromCategoryDialog" :loading="importProductsFromCategoryDialog.isLoading" class="mb-4 mr-2" small style="width: 200px;" color="warning">Import from Category</v-btn>
                    <v-btn @click="openImportProductsFromTagDialog" class="mb-4" small style="width: 200px;" color="warning">Import from Tag</v-btn>
                  </span>
                  <div v-if="data.metadata.discountOnProductsItem">
                    <div v-for="(item, index) in data.metadata.discountOnProductsItem" :key="index">
                      <span class="d-flex flex-row">
                        <v-text-field class="mr-2" dense outlined label="Quantity Limit" v-model="item.qtyLimit" type="number"/>
                        <v-select
                          dense
                          class="mr-2"
                          :items="['None','Limit per Order', 'Limit per Customer']"
                          label="Limit Options"
                          outlined
                          v-model="item.limitType"
                        ></v-select>
                        <v-text-field class="mr-2" :hint="item.productName + ' ' + utils.formatCurrency(item.regularPrice)" persistent-hint dense outlined label="Product ID" v-model="item.productId"/>
                        <v-text-field class="mr-2" dense outlined type=number label="Discount Value" v-model="item.discountValue"/>
                        <v-select
                          dense
                          :items="['Percentage', 'Amount']"
                          label="Discount Type"
                          outlined
                          v-model="item.discountType"
                        ></v-select>
                        <v-btn @click="removeDiscountOnProductsItem(index)" fab x-small class="ml-2" color="error"><v-icon>mdi-close</v-icon></v-btn>
                      </span>
                    </div>
                  </div>
                </span>
                <div v-if="data.metadata.condition=='Gift Card'">
                  <v-text-field dense outlined type=number label="Gift Card Amount" v-model="data.metadata.giftCardAmount"/>
                </div>
                <span v-if="data.metadata.reward=='Discount on Order'" class="d-flex flex-row">
                  <v-text-field class="mr-2" dense outlined type=number label="Discount Value" v-model="data.metadata.discountValue"/>
                  <v-select
                    dense
                    :items="['Percentage', 'Amount']"
                    label="Discount Type"
                    outlined
                    v-model="data.metadata.discountType"
                  ></v-select>
                </span>
                <span v-if="data.metadata.reward=='Free Items'" class="d-flex flex-column">
                  <span class="d-flex flex-row align-center">
                    <v-btn @click="addNewFreeItem" class="mb-4 mr-2" small style="width: 200px;" color="info">Add Product</v-btn>
                    <v-checkbox v-model="data.metadata.freeItemRequireChoice" label="Customer has to choose one."/>
                    <!-- <v-btn @click="openImportProductsFromCategoryDialog" :loading="importProductsFromCategoryDialog.isLoading" class="mb-4 mr-2" small style="width: 200px;" color="warning">Import from Category</v-btn> -->
                    <!-- <v-btn @click="openImportProductsFromTagDialog" class="mb-4" small style="width: 200px;" color="warning">Import from Tag</v-btn> -->
                  </span>
                  <div v-if="data.metadata.freeItems">
                    <div v-for="(item, index) in data.metadata.freeItems" :key="index">
                      <span class="d-flex flex-row">
                        <v-text-field class="mr-2" :hint="item.productName + ' ' + utils.formatCurrency(item.regularPrice)" persistent-hint dense outlined label="Product ID" v-model="item.productId"/>
                        <v-btn @click="removeFreeItem(index)" fab x-small class="ml-2" color="error"><v-icon>mdi-close</v-icon></v-btn>
                      </span>
                    </div>
                  </div>
                </span>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog 
      v-model="importProductsFromCategoryDialog.isOpen"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"  
    >
      <v-card>
        <v-card-title class=" d-flex flex-column text-h5">
          <span>Import Products from Category</span>
        </v-card-title>
        <v-card-text>
          <!-- <v-text-field outlined label="Search Categories" v-model="importProductsFromCategoryDialog.search" @change="searchProductCategoriesForIPFCD"/> -->
          <div v-for="(category,index) in importProductsFromCategoryDialog.data" :key="index">
            <b>{{ category.label }}</b>
            <div v-for="(subcat,index2) in category.children" :key="index2" class="ml-4">
              <span>{{ subcat.label }}</span>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeImportProductsFromCategoryDialog">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from 'axios';
import scanSystem from "../../plugins/scanSystem"
import {mapGetters, mapMutations} from "vuex";
import utils from '../../plugins/helpers'
export default {
  data () {
    return {
      utils: utils,
      lastScanTarget: '',
      conditions: ['None','Minimum Order Amount', 'Purchase Product Combination'],
      rewards: ['Discount on Order', 'Discount on Products', 'Gift Card', 'Free Items'],
      availableToTypes: ['All', 'New Customers', 'Existing Customers', 'The First X Customers', 'The Xth Customer','Custom'],
      scanInProgress: false,
      importProductsFromCategoryDialog: {
        isOpen: false,
        isLoading: false,
        search: '',
        data: []
      },
      importProductsFromTagDialog: {
        isOpen: false,
        isLoading: false,
        search: '',
        data: []
      },
      loader: true,
      snackObj: {
        state: false,
        color: '',
        text: ''
      },
      editMode: false,
      updateIsLoading: false,
      data: {},
      singular: "Promotion",
      singularLower: "promotion",
      plural: "Promotions",
      pluralLower: "promotions",
    }
  },
  watch: {
    scanBus: {
      handler(){
        console.log("Promotion.vue: ScanBus Changed")
        if(!this.scanBus.data || this.scanBus.data == "" || this.scanBus.data == undefined){
          console.log("Watcher detected that scanbus was reset. Terminating handoff to reserving handler: promotionHandler.")
          return
        }
        if(this.scanBus.handler == 'promotion'){
          console.log('Promotion Handler taking over.')
          this.promotionHandler()
        }
      },
      deep: true
    },
  },
  beforeDestroy(){
    this.deactivatePromotionScanSense()
  },
  async mounted(){
    try {
      this.loader = true;
      let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`)
      if(res.data.error) throw res.data.error

      this.data = res.data.data

      this.activatePromotionScanSense()
    } catch (error) {
      console.error(error)
      this.snack(error.msg || error.msg?.message || error, "error");
    } finally {
      this.loader = false;
    }
  },
  computed: {
    ...mapGetters(['getId', 'getEndpoint', "isAllowed", 'isManaged', 'lookupUsername', 'scanBus']),
  },
  methods: {
    ...mapMutations([
      'setScanBus',
      'resetScanBus',
    ]),
    async promotionHandler(){
      try {
        console.log("Promotion Handler Fired")

        this.setScanBus({data: ""})
        
        let type = scanSystem.validateType(this.scanBus.data)
        let s = this.scanBus.data.replace(/\\\/\/\\=/,'')
        let id = (s.replace(`${type}-`, ''));
        
        if(type==='INV'){
          this.scanStatusColor = 'green'
        
          console.log("Lets go to the scanned order: ", id)
          this.$router.push({path: `/orders/view/${id}`})
        }else{
          this.scanStatusColor = 'green'
        
          console.log("Lets add this product to the invoice: ", id, type)
          let prod = await this.lookupProduct(id, type)
        
          if(!prod){
            this.scanStatusColor = ''
            console.log("Product could not be found at this time.")
            return
          }
          
          if(this.lastScanTarget == 'conditions'){
            this.addNewPPCItem({productId: prod.id, productName: prod.name, regularPrice: prod.regularPrice})
          }
          if(this.lastScanTarget == 'rewards'){
            if(this.data.metadata.reward=='Discount on Products'){
              this.addNewDiscountOnProductsItem({productId: prod.id, productName: prod.name, regularPrice: prod.regularPrice})
            }
            if(this.data.metadata.reward=='Free Items'){
              this.addNewFreeItem({productId: prod.id, productName: prod.name, regularPrice: prod.regularPrice})
            }
          }

          this.scanStatusColor = ''
        }
      } catch (error) {
        this.scanStatusColor = ''
        console.log(error)
        this.snack(error)
      }
    },
    activatePromotionScanSense(){
      console.log('PROMOTION.JS: 💸✅ Promotion Scan Sense Active.')
      window.addEventListener('keydown', this.keydown)  
    },
    deactivatePromotionScanSense(){
      console.log('PROMOTION.JS: 💸❌ Promotion Scan Sense Inactive.')
      window.removeEventListener('keydown', this.keydown)  
    },
    keydown(e){

      //console.log('APP', e)
      this.cancelClearScanStringTimeout()
      this.clearScanStringTimeout()

      //user is not in chording mode or QUICKLIST mode(could either be typing or scanning)
      
      if(e.key==="Enter"){ //if keypress is enter
        //determine if scanString is a barcode
        let isBarcode = false

        if(this.scanString?.length>5){  //a barcode will at least have a 5 char signature \//\= and at least 1 character. if this is not met, we can assume its not valid
          isBarcode = scanSystem.validateBarcode(this.scanString)
        }
        
        if(isBarcode){ // if it is a barcode 
          
          // push to scanbus 
          this.setScanBus({data: "x"})
          this.setScanBus({data: this.scanString})
        }

      }else{ // keypress is not enter

        if(!this.scanString || this.scanString === "" || this.scanString === undefined || this.scanString === null) // if this is excluded, the barcode will always have undefined as the first character
          this.scanString = e.key
        else this.scanString += e.key

      }
    },
    async lookupProduct(s, type){
      try {
        let res;
        if(type === "SKU")
          res = await axios.get(`${this.getEndpoint}/api/products/bySKU/${s}`)
        if(type === "ID")
          res = await axios.get(`${this.getEndpoint}/api/products/${s}`)
        if(res.data.error) throw res.data.error
        if(!res.data.data) throw 'External barcode is not in DB.'
        return res.data.data
      } catch (error) {
        if(error.customError){
          console.error(error)
          this.snack('External barcode is not in DB.')
        }
        else this.snack(error)
      } 
    },
    clearScanString(){
      this.scanString = ''
      this.scanInProgress = false
      console.log("🔁 Scan String Cleared")
    },
    clearScanStringTimeout(){
      this.timeout = setTimeout(this.clearScanString,500)
    },
    cancelClearScanStringTimeout(){
      clearTimeout(this.timeout)
    },
    async openImportProductsFromCategoryDialog(){
      try {
        this.importProductsFromCategoryDialog.isOpen = true
        this.importProductsFromCategoryDialog.isLoading = true;
        let res = await axios.get(`${this.getEndpoint}/api/productcategories/byFamilies`)
        if(res.data.error) throw res.data.error

        this.importProductsFromCategoryDialog.data = res.data.data
        console.log(this.importProductsFromCategoryDialog.data)
      } catch (error) {
        console.log(error)
        this.snack(error)
      } finally {
        this.importProductsFromCategoryDialog.isLoading = false;
      }
    },
    closeImportProductsFromCategoryDialog(){
      this.importProductsFromCategoryDialog = {
        isOpen: false,
        isLoading: false,
        search: '',
        data: [],
      }
    },
    async searchProductCategoriesForIPFCD(){
      try {
        this.importProductsFromCategoryDialog.isLoading = true;
        let res = await axios.get(`${this.getEndpoint}/api/productcategories/byFamilies`)
        if(res.data.error) throw res.data.error

        this.importProductsFromCategoryDialog.data = res.data.data
        console.log(this.importProductsFromCategoryDialog.data)
        
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      } finally {
        this.importProductsFromCategoryDialog.isLoading = false
      }
    },
    openImportProductsFromTagDialog(){
      this.importProductsFromTagDialog.isOpen = true
    },
    closeImportProductsFromTagDialog(){
      this.importProductsFromTagDialog = {
        isOpen: false,
        isLoading: false,
        products: [],
      }
    },
    addNewDiscountOnProductsItem(data){
      let obj = {}
      if(data) {
        console.log(data)
        obj = {productId: data.productId, productName: data.productName, regularPrice: data.regularPrice, quantity: 1}
      }
      if(!this.data.metadata.discountOnProductsItem){
        this.data.metadata.discountOnProductsItem = [obj]
      }else {
        this.data.metadata.discountOnProductsItem.splice(0,0,obj)
      }
      this.$forceUpdate()
    },
    removeDiscountOnProductsItem(index){
      if(this.data.metadata.discountOnProductsItem && this.data.metadata.discountOnProductsItem.length > 0){
        this.data.metadata.discountOnProductsItem.splice(index,1)
        this.$forceUpdate()
      }
    },
    addNewPPCItem(data){
      let obj = {}
      if(data) {
        obj = {productId: data.productId, productName: data.productName, regularPrice: data.regularPrice, quantity: 1}
      }
      if(!this.data.metadata.ppcItems){
        this.data.metadata.ppcItems = [obj]
      }else {
        this.data.metadata.ppcItems.splice(0,0,obj)
      }
      this.$forceUpdate()
    },
    removePPCItem(index){
      if(this.data.metadata.ppcItems && this.data.metadata.ppcItems.length > 0){
        this.data.metadata.ppcItems.splice(index,1)
        this.$forceUpdate()
      }
    },
    addNewFreeItem(data){
      let obj = {}
      if(data) {
        obj = {productId: data.productId, productName: data.productName, regularPrice: data.regularPrice, quantity: 1}
      }
      if(!this.data.metadata.freeItems){
        this.data.metadata.freeItems = [obj]
      }else {
        this.data.metadata.freeItems.splice(0,0,obj)
      }
      this.$forceUpdate()
    },
    removeFreeItem(index){
      if(this.data.metadata.freeItems && this.data.metadata.freeItems.length > 0){
        this.data.metadata.freeItems.splice(index,1)
        this.$forceUpdate()
      }
    },
    async update(){
      try {
        this.loader = true;
        this.updateIsLoading = true
        let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`, this.data)
        if(res.data.error) throw res.data.error

        this.snack(`${this.singular} info Updated 🎉`, "success")
        this.editMode = false
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      } finally {
        this.loader = false;
        this.updateIsLoading = false
      }
    },
    async deleteData(){
      try {
        this.loader = true
        let res = await axios.delete(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`)
        if(res.data.error) throw res.data.error

        this.snack(this.singular+" Deleted 🎉", "success");

        await this.$router.go(-1)
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
    },
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    }
  }
}
</script>
